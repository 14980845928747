import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            impactArticles: [],
            impactArticleNextUrl: "",
            impactArticlePreviousUrl: "",
            impactTotalArticles: 0,
            impactArticleComments: [],
            discoverArticleComments: [],
            topicComments: [],
            limitError: "",
            ratedArticles: [],
            ratedArticleNextUrl: "",
            ratedArticlepreviousUrl: "",
            ratedTotalArticles: 0,
            impactSearchHistory: {},
            sharedImpactArticle: [],
            impactSharedArticleNextUrl: "",
            impactSharedArticlePreviousUrl: "",
            impactTotalSharedArticles: 0,
        }
    },
    actions,
    mutations,
    getters
}