import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state() {
    return {
      user_id: Cookies.get("user_id"),
      fullName: null,
      email: null,
      accessToken: "",
      profilePic: null,
      subscriptionPlans: [],
      lockOutPassword: null,
      firstLoginAttemp: false,
      subscriptionPackagesModal: false,
      linkedInLoginRedirection: false
    };
  },
  actions,
  mutations,
  getters,
};
