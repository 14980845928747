import ApiService from "../../../services/api.service";
import axios from "axios";

export default {

    // Engage Section Impact Article 

    fetchImpactArticle(context, payload) {
        ApiService.setAuthorizationHeader();
        return new Promise((resolve, reject) => {
            let requestUrl = `workspace/impact/list?limit=${payload.limit}&offset=${payload.offset}`;
            
            if (payload.query) {
                requestUrl += `&query=${payload.query}`
            }
            else if(payload.author) {
                requestUrl += `&author=${payload.author}`
            }
            
            else if (payload.fromDate) {
                requestUrl += `&from_date=${payload.fromDate}`;
            }
              else if (payload.toDate) {
                requestUrl += `&to_date=${payload.toDate}`;
              }
            ApiService.get(requestUrl)
                .then((response) => {
                    if (response.status === 200) {
                        context.commit('SET_IMPACT_ARTICLES', response.data);
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },
    createImpactArticle(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`workspace/impact/create/`, payload)
                .then((response) => {
                    if (response.status === 201) {
                        context.commit('UPDATE_IMPACT_ARTICLES', response.data);
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    updateImpactArticle(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`workspace/impact/${payload.get('story_id')}/update/`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        context.commit('UPDATE_IMPACT_ARTICLES', response.data);
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    deleteImpactArticle(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`workspace/impact/${payload.story_id}/destroy/`)
                .then((response) => {
                    if (response.status === 204) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    // Like And Dislike Impact Article 

    likeOrDislikeImpactArticle(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`workspace/impact/${payload.story_id}/action/`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    unlikeImpactArticle(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`workspace/impact/${payload.story_id}/action/delete/`)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    updateImpactLikeandDislike(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`workspace/impact/${payload.story_id}/action/update/`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    // Report of impact article

    impactArticleReport(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`workspace/impact/${payload.story_id}/report/`, payload)
                .then((response) => {
                    if (response.status === 201) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    // Comments Of Impact Article

    fetchImpactArticleComments(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`workspace/impact/${payload}`)
                .then((response) => {
                    if (response.status === 200) {
                        context.commit('SET_IMPACT_ARTICLES_COMMENTS', response.data);
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    impactArticleComment(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`workspace/impact/${payload.story_id}/comment/create/`, payload)
                .then((response) => {
                    if (response.status === 201) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    updateImpactArticleComment(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`workspace/impact/${payload.story_id}/comment/update/${payload.comment_id}/`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    deleteImpactArticleComment(_, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`workspace/impact/${payload.story_id}/comment/delete/${payload.comment_id}/`)
                .then((response) => {
                    if (response.status === 204) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    // Locations for impact article

    getLocations(_, location) {
        return new Promise((resolve, reject) => {
            axios.get(`location/?name=${location}`)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response)
                    }
                }).catch((err) => {
                    reject(err);
                })
        })
    },

    // Search history of impact article

    fetchimpactSearchHistory(context, _1) {
        ApiService.setAuthorizationHeader();
        return new Promise((resolve, reject) => {
          ApiService.get(`workspace/impact/search/history/`)
            .then((response) => {
              if (response.status === 200) {
                context.commit('SET_IMPACT_SEARCH_HISTORY', response.data)
                resolve(response)
              }
            }).catch((err) => {
              reject(err);
            })
        })
    },
    
    updateArticle({ commit }, articleDetails) {
        commit("UPDATE_IMPACT_ARTICLE",articleDetails);
     },

    async shareArticle(_, payload) {
    return await new Promise((resolve, reject) => {
        ApiService.post('user/portfolio/impact-story/share/', payload)
        .then((response) => {
            if (response.status === 201) {
            resolve(response)
            }
        }).catch((err) => {
            reject(err);
        });
    });
    },
    
    async impactArticleReaction(context, payload) {
        const reactionPayload = {
          "reaction" :  payload.reaction
        }
        return await new Promise((resolve, reject) => { 
          ApiService.post(`workspace/impact/${payload.articleId}/react/create/`, reactionPayload)
           .then(response => {
            if (response.status === 201){
                context.commit('article/articleReaction', response.data, { root: true })
              resolve(response)
            }
          }).catch(err => { 
            reject(err)
          })
        })
      },

      async deleteImpactArticleReaction(_, payload) { 
        return await new Promise((resolve, reject) => { 
          ApiService.delete(`workspace/impact/${payload.articleId}/react/delete/`)
            .then((response) => {
              if (response.status === 204) resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        })
      },

      async fetchUserImapctShareArticles(context, payload) {
        return await new Promise((resolve, reject) => {
          ApiService.setAuthorizationHeader();
          ApiService.get(`user/portfolio/impact-story/share/list/${payload.user_id}/?page=${payload.page}&page_size=${payload.page_size}`)
            .then((response) => {
              if (response.status === 200) {
                context.commit('SET_SHARED_IMPACT_ARTICLES', response.data)
                resolve(response);
              }
            })
            .catch((err) => {
              reject(err);
            })
        })
      },
}
