export default {
    setTitle(state, payload) {
        state.articleTitle = payload;
    },
    addReference(state, payload){
         state.references.push(payload);
    },
    removeReference(state, payload){
        let newreferences = state.references.filter((val,i)=>{
           return  val.id!==payload;
        })
        state.references = newreferences;
    },
    setAllReferences(state, payload){
        state.references = payload;
    },
    clearReferences(state){
        state.references = [];
    },  
    setArticleBody(state, payload) {
        state.articleBodyTEXT = payload["articleBodyTEXT"];
        state.articleBodyHTML = payload["articleBodyHTML"];
    },
    addToPublishedArticle(state, payload){
        state.publishedArticle=payload;
    },
    addCollaborator(state, payload){
        state.collaborators.push(payload);
    }
}