import axios from "axios";
import Cookies from "js-cookie";

const ApiService = {
    init(baseUrl) {
        axios.defaults.baseURL = baseUrl;
    },
    setHeader(headerKey, headerValue) {
        axios.defaults.headers.common[headerKey] = headerValue;
        console.log(axios.defaults.headers);
    },
    setAuthorizationHeader(access_token=null) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${access_token ?? Cookies.get("access_token")}`;
    },
    removeHeader() {
        axios.defaults.headers.common = {};
    },
    get(resource, data) {
        return axios.get(axios.defaults.baseURL + resource, data);
    },
    post(resource, data) {
        // used for post apis
        return axios.post(axios.defaults.baseURL + resource, data);
    },
    put(resource, data) {
        return axios.put(resource, data);
    },
    delete(data) {
        return axios.delete(data);
    },
    patch(resource, data) {
        return axios.patch(resource, data);
    },


}

export default ApiService;