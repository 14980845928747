import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            interests: [],
            languages: [],
            questionRating: [],
            features: [],
            shareArticles: [],
            shareArticleNextUrl: "",
            shareArticlepreviousUrl: "",
            shareTotalArticles: 0,
            ratedArticles: [],
            ratedArticleNextUrl: "",
            ratedArticlepreviousUrl: "",
            ratedTotalArticles: 0,
            interestGroupId: "",
            interestGroup: [],
            activeReactionId:'',
            reactedArticle: {},
            savedArticles: [],
            articlesFodlers: [],
            joinedFolders: [],
            searchedQueryList:[]
            
        }
    },
    actions,
    mutations,
    getters
}