export default {
    SET_IMPACT_ARTICLES(state, payload) {
        state.impactArticles = payload;
        state.impactArticleNextUrl = payload.next;
        state.impactArticlePreviousUrl = payload.previous;
        state.impactTotalArticles = payload.count;
    },
    UPDATE_IMPACT_ARTICLES(state, payload) {
        state.impactArticles.results.unshift(payload)
    },
    SET_IMPACT_ARTICLES_COMMENTS(state, payload){
        state.impactArticleComments = payload;
    },
    SET_IMPACT_SEARCH_HISTORY(state, payload){
        state.impactSearchHistory = payload;
    },
    UPDATE_IMPACT_ARTICLE(state, payload) { 
        state.impactArticles.results[payload.articleIndex] = payload.article
    },
    SET_SHARED_IMPACT_ARTICLES(state, payload){
        state.sharedImpactArticle = payload;
        state.impactSharedArticleNextUrl = payload.next;
        state.impactSharedArticlePreviousUrl = payload.previous;
        state.impactTotalSharedArticles = payload.count;
    }
}