
export default {
    getContent(state) {
        return state.editorContent;
    }, 
    getAllReferences(state){
        return state.references;
    },
    getNoOfReferences(state){
        return state.references.length;
    },
    getArticleTitle(state) {
        return state.articleTitle
    },
    getArticleBody(state) {
        return {
            articleBodyHTML: state.articleBodyHTML,
            articleBodyTEXT: state.articleBodyTEXT
        }
    },
    getPublishedArticle(state){
        return state.publishedArticle;
    },
    getAllCollaborators(state){
        return state.collaborators;
    }
}