import ApiService from "../../../services/api.service";

export default {
  // Get all user saved articles folder
  archiveFolderCreate({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/portfolio/archive-folder/create/", payload)
        .then((response) => {
          commit("ADD_NEW_FOLDER_TO_ARCHIVE", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveArticleToArchiveFolder({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `user/portfolio/archive-folder/${payload.folderId}/content/create/`,
        payload.article_details
      )
        .then((response) => {
           commit("SET_ARCHIVE_FOLDER_CONTENT", response.data)
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchArchiveFoldersList({ commit, state, rootGetters }, { visibility }) {
    commit("SET_FOLDER_SKELETON", true);
    let url = "user/portfolio/archive-folder/list/?visibility=" + visibility + "&user_id=" + rootGetters['profile/selectedUser'].id + "&ordring=-created_at";
    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then((response) => {
          commit("SET_ARCHIVE_FOLDER_LIST", response.data.results);
          commit("SET_FOLDER_SKELETON", false);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setSelectedFolder({ commit }, folder) {
    commit("SET_SELECTED_FOLDER", folder);
  },
  updateArchiveFolderName({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `user/portfolio/archive-folder/${payload.id}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_ARCHIVE_FOLDER", response.data); 
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteArchiveFolder({commit}, folderId) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `user/portfolio/archive-folder/${folderId}/delete/`)
        .then((response) => {
            commit("DELETE_ARCHIVE_FOLDER", folderId); 
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchArchiveFoldersContentList({ commit, state }, {archiveFolderId, contentType, userId}) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      const pageSize = contentType === 'articles' ? state.archiveArticlePageSize : state.archiveVideoAndPodcastPageSize
      ApiService.get(`user/portfolio/archive-folder/${archiveFolderId}/content/list/?content_type=${contentType}&user_id=${userId}&page=${state.currentArchiveContentPage}&page_size=${pageSize}&ordering=-created_at`)
        .then((response) => {
          if (response.status === 200) {
            commit("SET_ARCHIVE_FOLDER_CONTENT", response.data.results);
            commit("SET_ARCHIVE_CONTENT_TOTAL_COUNT", response.data.count);
            commit("SET_ARCHIVE_CONTENT_END_LIMIT", response.data.next ? true : false)
            resolve(response);
          }
        })
        .catch((err) => {
          commit('SET_FETCH_CONTENT_LOADER')
          reject(err);
        });
    });
  },
  async deleteArchiveFoldersContent({commit}, {archiveFolderId, contentId}) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `user/portfolio/archive-folder/${archiveFolderId}/content/${contentId}/delete/`
      )
        .then((response) => {
            commit("DELETE_ARCHIVE_FOLDER_ARTICLE", contentId);
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  emptyArchiveContentList({commit}){
    commit('EMPTY_ARCHIVE_CONTENT_LIST')
  },
  setActiveArchiveContentType({commit}, contentType){
    commit('SET_ACTIVE_ARCHIVE_CONTENT_TYPE', contentType)
  },
  toggleArchiveContentLoader({commit, rootState}){
    commit("folders/SET_FOLDER_MEDIA_SKELETON", !rootState.folders.folderMediaSkeleton, {root:true})
  },
  toggleArchiveArticleLoader({commit, state}) {
    commit('SET_FETCH_CONTENT_LOADER', state.folderSkeleton)
  },
  emptySelectedArchiveFolder({commit}){
    commit('EMPTY_SELECTED_ARCHIVE_FOLDER')
  },
  updateArchiveContentCurrentPage({commit, state}, pageAction='next'){
    switch(pageAction){
      case 'next':
        commit('SET_ARCHIVE_CONTENT_CURRENT_PAGE', state.currentArchiveContentPage + 1)
        break;
      case 'prev':
        commit('SET_ARCHIVE_CONTENT_CURRENT_PAGE', state.currentArchiveContentPage - 1)
        break;
      case 'move-first-page':
        commit('SET_ARCHIVE_CONTENT_CURRENT_PAGE', 1)
        break;
      case 'move-last-page':
        commit('SET_ARCHIVE_CONTENT_CURRENT_PAGE', Math.round(state.archiveContentTotalCount / 8))
        break;
    }
  }

};
