export default {
    getImpactArticles(state) {
        return state.impactArticles;
    },
    getImpactArticleNextUrl(state){
        return state.impactArticleNextUrl;
    },
    getImpactArticlePreviousUrl(state){
        return state.impactArticlePreviousUrl;
    },
    getImpactTotalArticles(state){
        return state.impactTotalArticles;
    },
    getImpactArticlesComments(state){
        return state.impactArticleComments;
    },
    getRecentImpactSearches(state){
        return state.impactSearchHistory;
    },
    getSharedImpactArticle(state){
        return state.sharedImpactArticle;
    },
    getImpactSharedArticleNextUrl(state){
        return state.impactSharedArticleNextUrl;
    },
    getImpactSharedArticlePreviousUrl(state){
        return state.impactSharedArticlePreviousUrl;
    },
    getImpactSharedTotalArticles(state){
        return state.impactTotalSharedArticles;
    },
}