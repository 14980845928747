import { createApp } from 'vue'

const app = createApp(App);
import App from '../App.vue';

const multiEvent = {
 functionWrapper(e) {
    /* add filters to handle event type before propagating to callback function for custom event handler */
    e.target.__handler__.fn(e)
  }
}

  app.directive('multiEvent', {
    bind: function(el, binding, vnode) {
      console.log('bind')
      el.__handler__ = binding.value
      binding.value.evt.forEach(e => el.addEventListener(e, this.functionWrapper))
    },
    unbind: function(el, binding) {
      console.log('unbind')
      el.__handler__.evt.forEach(e => el.removeEventListener(e, this.functionWrapper))
      el.__handler__ = null
    }
  })
  

 export default multiEvent;