import ApiService from "../../../services/api.service";
import Cookies from "js-cookie";

export default {
    setTitle(context, payload) {
        context.commit('setTitle', payload);
    },
    addReference(context, payload){
        context.commit('addReference', payload);
    },
    removeReference(context, payload){
        context.commit('removeReference', payload);
    },
    setAllReferences(context, payload){
        context.commit('setAllReferences', payload);
    },
    clearReferences(context){
        context.commit('clearReferences');
    },
    setArticleBody(context, payload){
        context.commit('setArticleBody', payload);
    },
    publishArticle(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setAuthorizationHeader();
            ApiService.post("workspace/transaction_article/add/", payload)
                .then(function (response) {
                    if (response.status === 201){
                        context.commit('addToPublishedArticle', response.data);
                        resolve(response);
                    }
                })
                .catch(function (error) {
                    reject(error);
                })
        })
    },
    fetchArticle(context){
        let article_id = "be5ec1fe-7e26-4630-983c-fdd74626708a";
        return new Promise((resolve, reject)=>{
            ApiService.get(`workspace/transaction_article/${article_id}/`)
            .then((response)=>{
                if(response.status === 200){
                    resolve(response);
                }
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    addCollaborator(context, payload){
        ApiService.setAuthorizationHeader();
        let requestObj = {
            email: payload.email,
            role: payload.role,
            workspace: payload.workspace,
        }
        return new Promise((resolve, reject)=>{
            ApiService.post(`workspace/collaborator/add/`, requestObj)
            .then((response)=>{
                if(response.status === 201){
                    let collaborator = {
                        ...response.data,
                        name: payload.name
                    }
                    context.commit("addCollaborator", collaborator);
                    resolve(response);
                }
            }).catch((err)=>{
                reject(err)
            })
        })
    }
}