export default {
  setInterests(state, payload) {
    state.interests = payload;
  },
  setFetchedLanguages(state, payload) {
    state.languages = payload;
  },
  setFeatures(state, payload) {
    state.features = payload;
  },
  setShareArticles(state, payload) {
    if (payload.results) {
      state.shareArticles = payload.results;
      state.shareArticleNextUrl = payload.next;
      state.shareArticlepreviousUrl = payload.previous;
      state.shareTotalArticles = payload.count;
    } else {
      state.shareArticles = payload;
    }
  },
  setDiscussionComments(state, payload) {
    state.topicComments = payload;
  },
  setRatedArticles(state, payload) {
    state.ratedArticles = payload.results;
    state.ratedArticleNextUrl = payload.next;
    state.ratedArticlepreviousUrl = payload.previous;
    state.ratedTotalArticles = payload.count;
  },
  clearRatedArticles(state) {
    state.ratedArticles = [];
  },
  setInterestGroup(state, payload) {
    state.interestGroup = [];
    payload.forEach((element) => {
      state.interestGroup.push(element);
      state.interestGroupId = element.id;
    });
  },
  clearInterestGroup(state) {
    state.interestGroup = [];
  },
  // articleReaction(state, payload) {
  //   console.log('articleReaction',payload);
  //   console.log(' state.ratedArticles ',state.ratedArticles);
  //   console.log(' state.savedArticles',state.savedArticles);
  //   console.log(' state.shareArticles',state.shareArticles);
    
  //   // const index = state.feeds.findIndex(feed => feed.id === payload.id);
  //   // if (index !== -1) {
  //   //   state.feeds[index] = { ...payload };
  //   // }
  //   state.activeReactionId = payload;
  //   state.reactedArticle = payload;
  //   console.log('state.reactedArticle',state.reactedArticle);
    
  // },

  // articleReaction(state, payload) {
  //   console.log('articleReaction', payload);
  //   console.log('state.ratedArticles', state.ratedArticles);
  //   console.log('state.savedArticles', state.savedArticles);
  //   console.log('state.shareArticles', state.shareArticles);
  

  //   const updateArticleInArray = (array, isNested = false) => {
  //     if (!Array.isArray(array) || array.length === 0) {
  //       console.log('return',Array.isArray(array),array.length);
  //       return;
  //     }
  
  //     array.forEach(item => {
  //       if (isNested) {
         
  //         if (Array.isArray(item.save_article) && item.save_article.length > 0) {
  //           const nestedIndex = item.save_article.findIndex(savedArticle => savedArticle.article_data.id === payload.id);
  //           if (nestedIndex !== -1) {
  //             item.save_article[nestedIndex].article_data = {
  //               ...item.save_article[nestedIndex].article_data,
  //               ...payload 
  //             };
  //           }
  //           console.log('if',nestedIndex  );
            
  //         }
  //       } else {
  //         const index = array.findIndex(article => article.id === payload.id);
  //         if (index !== -1) {
  //           array[index] = { ...array[index], ...payload };
  //           console.log('array[index]',array[index]);
          
  //         }
  //       }
  //     });
  //   };
  
  
  //   updateArticleInArray(state.ratedArticles);
  
 
  //   updateArticleInArray(state.savedArticles, true);
  
  //   updateArticleInArray(state.shareArticles);
  //   state.activeReactionId = payload.id;
  //   state.reactedArticle = payload;
  // },  
  articleReaction(state, payload) {
    // console.log('articleReaction payload:', payload);
  
    // // Ensure each array exists before calling the update function
    // console.log('state.ratedArticles:', state.ratedArticles);
    // console.log('state.savedArticles:', state.savedArticles);
    // console.log('state.shareArticles:', state.shareArticles);
  
    // const updateArticleInArray = (array, isNested = false) => {
    //   console.log('Array before check:', array);
  
    //   if (!Array.isArray(array) || array.length === 0) {
    //     console.log('Array is either not an array or empty:', Array.isArray(array), 'Length:', array.length);
    //     return;
    //   }
  
    //   array.forEach(item => {
    //     if (isNested) {
    //       if (Array.isArray(item.save_article) && item.save_article.length > 0) {
    //         const nestedIndex = item.save_article.findIndex(savedArticle => savedArticle.article_data.id === payload.id);
    //         if (nestedIndex !== -1) {
    //           item.save_article[nestedIndex].article_data = {
    //             ...item.save_article[nestedIndex].article_data,
    //             ...payload // Assuming payload contains the updated article data
    //           };
    //           console.log('Updated nested article:', item.save_article[nestedIndex]);
    //         } else {
    //           console.log('No matching nested article found');
    //         }
    //       } else {
    //         console.log('No save_article array or it is empty:', item.save_article);
    //       }
    //     } else {
    //       const index = array.findIndex(article => article.id === payload.id);
    //       if (index !== -1) {
    //         array[index] = { ...array[index], ...payload };
    //         console.log('Updated article in array:', array[index]);
    //       } else {
    //         console.log('No matching article found in array');
    //       }
    //     }
    //   });
    // };
  
    // // Log before updating to ensure arrays are correct
    // console.log('Rated Articles Before Update:', state.ratedArticles);
    // console.log('Saved Articles Before Update:', state.savedArticles);
    // console.log('Share Articles Before Update:', state.shareArticles);
  
    // // Call the update function on each array
    // if (Array.isArray(state.ratedArticles)) {
    //   updateArticleInArray(state.ratedArticles);
    // }
    
    // if (Array.isArray(state.savedArticles)) {
    //   updateArticleInArray(state.savedArticles, true); // Nested structure
    // }
    
    // if (Array.isArray(state.shareArticles)) {
    //   updateArticleInArray(state.shareArticles);
    // }
  
    // Update the active reaction
    state.activeReactionId = payload.id;
    state.reactedArticle = payload;
  },
  
  setSavedArticles(state, payload) {
    state.savedArticles = payload;
  },
  setSaveArticleFolders(state, payload) {
    state.articlesFodlers = payload;
  },
  setJoinedSaveArticleFolders(state, payload) {
    state.joinedFolders = payload;
  },
  updateArticles(state, payload){
   
    let articleArray = []
    let savedArticleObject = {}
    let articlePosition = -1
    let ratedArticlePosition = -1
    let savedArticlePosition = -1

    switch(payload.articleType){
      case 'Shared Articles':
        articleArray = [...state.shareArticles]
        articlePosition = articleArray.findIndex(article=>article.article.id === payload.article.id[0])
        break;
      case 'Rated Articles':
        articleArray = [...state.ratedArticles]
       ratedArticlePosition = articleArray.findIndex(article=>article.id === payload.article.id[0])
        break;
      default:
        savedArticleObject = {...state.savedArticles}
        savedArticlePosition = savedArticleObject.save_article.findIndex(article=>article.article_data.id === payload.article.id[0])
        break;
    }

    if (articlePosition > -1 && payload.articleType === 'Shared Articles'){
      articleArray[articlePosition].article.article_reactions = payload.article.article_reactions
      articleArray[articlePosition].article.user_reaction = payload.article.user_reaction
      articleArray[articlePosition].article.users_reaction_representation = payload.article.users_reaction_representation
    } 
    if(ratedArticlePosition > -1 && payload.articleType === 'Rated Articles'){
        articleArray[ratedArticlePosition].article_reactions = payload.article.article_reactions
        articleArray[ratedArticlePosition].user_reaction = payload.article.user_reaction
        articleArray[ratedArticlePosition].users_reaction_representation = payload.article.users_reaction_representation
    }
    if(savedArticlePosition > -1 && payload.articleType === 'Saved Articles'){
      savedArticleObject.save_article[savedArticlePosition].article_data.article_reactions = payload.article.article_reactions
      savedArticleObject.save_article[savedArticlePosition].article_data.user_reaction = payload.article.user_reaction
      savedArticleObject.save_article[savedArticlePosition].article_data.users_reaction_representation = payload.article.users_reaction_representation

  }

    switch(payload.articleType){
      case 'Shared Articles':
        state.shareArticles = [...articleArray]
        break;
      case 'Rated Articles':
        state.ratedArticles = [...articleArray]
        break;
      default:
        state.savedArticles = {...savedArticleObject}
        break;
    }

  },
  setSearchedQuery(state, payload){
    state.searchedQueryList = payload
  }
};
