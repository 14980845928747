import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export default {
    namespaced: true,
    state() {
        return {
            articleTitle: "",
            references: [],
            articleBodyHTML: "",
            articleBodyTEXT: "",
            publishedArticle: 
                {body_markdown:"<p>Testing...</p>",
                body_text:"Testing...",
                id:"6e18e2f6-f857-4914-83df-24fd7f33817c",
                is_draft:false,
                title:"Hello",
                workspace_id:"289dac10-3660-4499-9ca7-25ba2279e08f"}
            ,
            collaborators: [
                {
                    id:"0a648f04-77c2-46f2-a017-147cf47eb3a6",
                    role:"editor",
                    user:6,
                    workspace:"289dac10-3660-4499-9ca7-25ba2279e08f",
                    name:"string"
                }
            ],
        }
    },
    actions,
    mutations,
    getters
}