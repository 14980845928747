export default {
  setTimeZones(state, payload) {
    let zones = [];
    payload.map((val) => {
      zones.push({
        value: val,
      });
    });
    state.timeZones = zones;
  },
  setLocation(state, payload) {
    state.location = payload;
  },
};
