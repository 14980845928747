import axios from 'axios'

export default {

     async postPaymentCardDetails(_,paymentCardDetails) {  
        return axios.post('/cards/add-card/', paymentCardDetails)

    },
    async getPaymentCards(_, payload = null) { 
        const response = await axios.get('/cards/get-cards/')
        if (response.status === 200)
            return response.data.data
    },
    async removePaymentCard(_, cardId) { 
        return await axios.delete(`/cards/remove-card/${cardId}/`)
    }

}