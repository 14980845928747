import axios from "axios";
import TokenService from "./storage.service.js";
// import store from '../store/index.js';
import ApiService from "./api.service.js";
import Cookies from "js-cookie";
import router from "../router.js";
import store from "../store/index.js";

//Request interceptor for API calls
const RequestInterceptor = {
    interceptors() {
        axios.interceptors.response.use(
            (response) => {                    
                return Promise.resolve(response);
            },
            async (error) => {
                if (error.response.status === 401 && !error.config._retry) {
                    // Removing the user from state and cookies
                    store.commit("auth/setUser", {
                        accessToken: null,
                        user_id: null,
                    });
                    Cookies.remove("access_token");
                    Cookies.remove("refresh_token");
                    router.push("/");
                }
                return Promise.reject(error);
            }
        );
    },
};

export default RequestInterceptor;
