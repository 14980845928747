import axios from "axios";
import ApiService from "../../../services/api.service.js";
import Cookies from "js-cookie";
export default {
  async getTimeZones(context) {
    return new Promise((resolve, reject) => {
      ApiService.get("location/timezones/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setTimeZones", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async getLocation({ commit }, query) {
    try {
      const res = await axios.get(`https://nominatim.openstreetmap.org/search?q=${query}&format=json`);
      const loc = res.data.map(val => ({
        label: val.display_name,
        value: val.display_name,
        lat: val.lat,
        lon: val.lon
      }));
      commit('setLocation', loc);
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  }
};

