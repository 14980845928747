import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export default {
    namespaced: true,
    state() {
      return {
        accountType: null,
        certificates: [],
        experiences: [],
        audienceGroups: [],
        topics: [],
        eventsCount:null,
        eventsCurrentPage:null,
        eventsNext:0,
        eventsPrevious:0,
        totalEvents: 0,
        interests: [],
        selectedEvent: null,
        personalInfo: {},
        friends: [],
        friendsCount: 0,
        isConnectionsLoading: true,
        connectionsInGroup: {},
        isGroupLoadings: true,
        group: {},
        events: [],
        languages: [],
        timeline: [],
        // mission: '',
        // introduction: '',
        profile: {},
        profilePicture: "",
        coverPhoto: "",
        userProfile: {},
        // getUserProfile : [],
        connectionStatus: {},
        requestCount: 0,
        notificationCount: 0,
        userRequests: [],
        totalNotifications: [],
        loggedInUserDetails: [],
        userCategories: [],
        visibleSubscriptionModal: true,
        photoResume: [],
        savedFoldersComments: [],
        profileSkeletonLoader: true,
        experienceSkeletonLoader: true,
        languagesSkeletonLoader: true,
        photoResumeSkeletonLoader: true,
        connections: [],
        eventsSkeletonLoader: true,
        currentActiveTab:null,
        selectedUser:{}
      };
    },
    actions,
    mutations,
    getters
}