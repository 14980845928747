import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import ApiService from "./services/api.service.js";
import TokenService from "./services/storage.service.js";
import store from "./store/index.js";
import RequestInterceptor from "./services/interceptors.service.js";
import clickOutside from "./directives/click-outside";
import MultipleEvents from "./directives/multiple-events.js";
import mitt from "mitt";
import Popper from "vue3-popper";
import "@/assets/style.css";
import vue3GoogleLogin from 'vue3-google-login'
// import Antd from 'ant-design-vue';
import {
  Col,
  Row,
  Select,
  Modal,
  Drawer,
  Collapse,
  Form,
  Button,
  Textarea,
  Pagination,
  InputPassword,
  Input,
  Dropdown,
  Tabs,
  Carousel,
  Skeleton,
  Menu,
  Radio,
  Checkbox,
  DatePicker,
  Upload,
  Tooltip,
  Spin,
  Tree,
  TreeSelect,
  TimePicker,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
// import VueGoogleMaps from "@fawmi/vue-google-maps";



const app = createApp(App);

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API);

// If token exists set header
// if (TokenService.getToken()) {
//     ApiService.setAuthorizationHeader();
// }

const components = [
  Col,
  Row,
  Select,
  Modal,
  Drawer,
  Collapse,
  Form,
  Button,
  Textarea,
  Pagination,
  InputPassword,
  Input,
  Dropdown,
  Tabs,
  Carousel,
  Skeleton,
  Menu,
  Radio,
  Checkbox,
  DatePicker,
  Upload,
  Tooltip,
  Spin,
  Tree,
  TreeSelect,
  TimePicker,
];

RequestInterceptor.interceptors();

const emitter = mitt();
// app.use(Antd);
components.forEach((component) => {
  app.use(component);
});
// app.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyABy0de4oAU34qkNVvF4xiiVmvS9zdeiMY",
//   },
// });
app.use(router);
app.use(store);
app.use(Popper);
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.directive("click-outside", clickOutside);
app.directive("MultipleEvents", MultipleEvents);
app.config.globalProperties.emitter = emitter;
app.mount("#app");