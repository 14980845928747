export default {
  feeds(state) {
    return state.feeds;
  },
  isLoading(state) {
    return state.loading;
  },
  getContributors(state){
    return state.contributors;
  },
  getFollowedOrgs(state){
    return state.followedOrgs
  },
  error(state) {
    return state.loading;
  },
  getAllContributors(state){
    return state.allContributors
  },
  getReactedFeed(state){
    return state.reactedFeed;
  },
  feedScrollHeight: (state) => state.innerHeight,
  feedPageLimit: (state) => state.feedPage,
  hasMoreFeedPages: (state) => state.hasMoreFeedPages,
  recommendedConnections: (state) => state.recommendedConnections,
  recommendedOrganizations: (state) => state.recommendedOrganizations,
  activities: (state) => state.feeds.filter(feed=> feed.content_type === 'image')
};
