import Cookies from "js-cookie";

export default {
    setUser(state, payload) {
        state.accessToken = payload.accessToken;
        state.user_id = payload.user_id;
    },
    setPersonalInfo(state, payload) {
        state.fullName = payload.fullName;
        state.email = payload.email;
        state.profilePic = payload.profilePic;
    },
    setPasswordInfo(state, payload) {
        state.password = payload.password;
        state.confirmPassword = payload.confirmPassword
    },
    setLockoutPassword(state, payload){
        state.lockOutPassword = payload.password;
    },
    setUserFirstLoginAttemp(state, payload){
        state.firstLoginAttemp = payload;
    },
    setEmail(state, payload){
        state.email = payload;
    },
    setSubscriptionPackagesVisibilty(state, payload){
        state.subscriptionPackagesModal = payload;
    },
    setSubscriptionPlans(state, payload){
        state.subscriptionPlans = payload;
    },
    SET_LINKEDIN_LOGIN_REDIRECTION(state, payload){
        state.linkedInLoginRedirection = payload
    }
}